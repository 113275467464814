
import Vue, { PropOptions } from 'vue'

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true,
    } as PropOptions<string>,
    to: {
      type: String,
      required: true,
    } as PropOptions<string>,
  },
})
