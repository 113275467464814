import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { PatientStudyStatus } from './patientStudy'

export enum Actions {
  fetch = 'fetch',
}

export enum Mutations {
  FETCH = 'FETCH',
}

export interface PatientStudyConfig {
  states: PatientStudyStatus[]
  edges: {
    from: PatientStudyStatus
    to: PatientStudyStatus
    siteUser: boolean
  }[]
}

export const state = () => ({
  config: {} as PatientStudyConfig,
})

export const studyConfigStore = (method: Actions | Mutations) =>
  `studyConfig/${method}`

export type StudyConfigState = ReturnType<typeof state>

export const mutations: MutationTree<StudyConfigState> = {
  [Mutations.FETCH](state, data) {
    state.config = data
  },
}

export const actions: ActionTree<StudyConfigState, RootState> = {
  async [Actions.fetch]({ commit }) {
    const config = await this.$axios.$get<PatientStudyConfig>(
      `/v1/patient-study-config`
    )
    commit(Mutations.FETCH, config)
  },
}
