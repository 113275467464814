
import Vue from 'vue'
import { AuthGetters, authStore } from '~/store/auth'
import AdminMenu from './AdminMenu.vue'
import MenuItemLink from './MenuItemLink.vue'
import PatientMenu from './PatientMenu.vue'
import SiteGroupUserMenu from './SiteGroupUserMenu.vue'
import SiteUserMenu from './SiteUserMenu.vue'
import UserMenu from './UserMenu.vue'

export default Vue.extend({
  components: {
    AdminMenu,
    MenuItemLink,
    PatientMenu,
    SiteGroupUserMenu,
    SiteUserMenu,
    UserMenu,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.getters[authStore(AuthGetters.isAdmin)]
    },
    isSiteGroupUser(): boolean {
      return this.$store.getters[authStore(AuthGetters.isSiteGroupUser)]
    },
    isSiteUser(): boolean {
      return this.$store.getters[authStore(AuthGetters.isSiteUser)]
    },
    isPatient(): boolean {
      return this.$store.getters[authStore(AuthGetters.isPatient)]
    },
    isFocusMode(): boolean {
      return !!this.$store.getters.isFocusMode
    },
  },
  watch: {
    $route: {
      handler() {
        this.isOpen = false
      },
      deep: true,
    },
  },
  methods: {
    toggleNavigation() {
      this.isOpen = !this.isOpen
    },
  },
})
