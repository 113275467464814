import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { Claim, Role } from './auth'

export interface User {
  id: string
  claims: Set<Claim>
  claimsUser: Set<Claim>
  claimsRole: Set<Claim>
  roles: Set<Role>
}

export interface UserEntity {
  id: string
  roles: string[]
  roleClaims: string[]
  userClaims: string[]
}

export enum Actions {
  fetchUsers = 'fetchUsers',
  updateUserRoles = 'updateUserRoles',
  updateUserClaims = 'updateUserClaims',
}

export enum Mutations {
  UPDATE_USERS = 'UPDATE_USERS',
  UPDATE_USER = 'UPDATE_USER',
}

export enum Getters {
  userById = 'userById',
}

export const userStore = (method: Actions | Mutations | Getters) =>
  `user/${method}`

export const state = () => ({
  users: [] as Array<User>,
})

export type UserState = ReturnType<typeof state>

export const mutations: MutationTree<UserState> = {
  [Mutations.UPDATE_USERS](state, data: UserEntity[]) {
    state.users = data.map(mapEntityToModel)
  },
  [Mutations.UPDATE_USER](state, data: UserEntity) {
    const user = mapEntityToModel(data)

    const index = state.users.findIndex((u) => u.id === user.id)
    if (index !== -1) {
      state.users.splice(index, 1, user)
    } else {
      state.users.push(user)
    }
  },
}

export const actions: ActionTree<UserState, RootState> = {
  async [Actions.fetchUsers]({ commit }) {
    const users = await this.$axios.$get<UserEntity[]>(`/v1/users`)
    commit(Mutations.UPDATE_USERS, users)
  },
  async [Actions.updateUserRoles]({ commit }, { uid, roles }) {
    const user = await this.$axios.$patch<UserEntity>(
      `v1/users/${uid}/roles`,
      roles
    )
    commit(Mutations.UPDATE_USER, user)
  },
  async [Actions.updateUserClaims]({ commit }, { uid, claims }) {
    const user = await this.$axios.$patch<UserEntity>(
      `v1/users/${uid}/claims`,
      claims
    )
    commit(Mutations.UPDATE_USER, user)
  },
}

export const getters: GetterTree<UserState, UserState> = {
  [Getters.userById]: (state) => (uid: string) =>
    state.users.find((u) => u.id === uid),
}

function mapEntityToModel(user: UserEntity): User {
  return {
    id: user.id,
    claims: new Set(
      [...user.userClaims, ...user.roleClaims].map((c) => c as Claim)
    ),
    claimsUser: new Set(user.userClaims.map((c) => c as Claim)),
    claimsRole: new Set(user.roleClaims.map((c) => c as Claim)),
    roles: new Set(user.roles.map((r) => r as Role)),
  }
}
