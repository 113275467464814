// https://docs.ambeedata.com/#weather-intro
import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState, UserQuery } from '~/store'
import { toInstant } from './utils'

export interface WeatherEntity {
  since: string
  temperature: number
  humidity: number
  pressure: number
  size: number
}

export interface WeatherInfo extends Omit<WeatherEntity, 'since'> {
  since: Instant
}

export const state = () => ({ infos: [] as Array<WeatherInfo> })

export type WeatherState = ReturnType<typeof state>

export const mutations: MutationTree<WeatherState> = {
  FETCH_WEATHER(state, data: WeatherEntity[]) {
    state.infos = data.map((e) => ({
      ...e,
      since: toInstant(e.since),
    }))
  },
}

export const actions: ActionTree<WeatherState, RootState> = {
  async fetchWeather({ commit }, query: UserQuery) {
    const weatherData = await this.$axios.$get<WeatherEntity[]>(`/v1/weather`, {
      params: {
        patientId: query.uid,
        since: query.since?.toString(),
        until: query.until?.toString(),
        interval: query.interval < 60 ? 60 : query.interval, // MME: Weather Intervall should be minimal 60 minutes. A finer resolution is not available
      },
    })
    commit('FETCH_WEATHER', weatherData)
  },
}
