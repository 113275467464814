import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState, UserQuery } from '~/store'
import { toInstant } from './utils'

export interface DeviceEntity {
  id: string
  patientId: string
  macAddress: string
  serialNumber: string
  registerTime: string
  lastUpload?: string
}

export interface Device {
  id: string
  patientId: string
  macAddress: string
  serialNumber: string
  registerTime: Instant
  lastUpload?: Instant
}

export interface DeviceInfosEntity {
  since: string
  worn: number
  onCharger: number
  alive: number
}

export interface DeviceInfo {
  since: Instant
  worn: number
  onCharger: number
  alive: number
}

export const state = () => ({
  deviceId: '' as string | null,
  deviceInfos: [] as Array<DeviceInfo>,
  devices: [] as Array<Device>,
})

export type DeviceState = ReturnType<typeof state>

export const mutations: MutationTree<DeviceState> = {
  FETCH_DEVICE_INFOS(state, data: DeviceInfosEntity[]) {
    state.deviceInfos = data.map((e) => ({
      ...e,
      since: toInstant(e.since),
    }))
  },

  FETCH_DEVICES(state, data: DeviceEntity[]) {
    state.deviceId = data[0]?.macAddress ?? null
    state.devices = data.map((d) => ({
      ...d,
      registerTime: toInstant(d.registerTime),
      lastUpload: d.lastUpload ? toInstant(d.lastUpload) : undefined,
    }))
  },
}

export const actions: ActionTree<DeviceState, RootState> = {
  async fetchDeviceInfos({ commit }, query: UserQuery) {
    const deviceInfoData = await this.$axios.$get<DeviceInfosEntity[]>(
      `/v1/patients/${query.uid}/deviceInfos`,
      {
        params: {
          since: query.since?.toString(),
          until: query.until?.toString(),
          interval: query.interval,
        },
      }
    )
    commit('FETCH_DEVICE_INFOS', deviceInfoData)
  },
  async fetchDevices({ commit }, pid: string) {
    const devices = await this.$axios.$get<DeviceEntity[]>(`/v1/devices`, {
      params: {
        patientId: pid,
        withUnregistered: true,
      },
    })
    commit('FETCH_DEVICES', devices)
  },
}

export function toDeviceFromEntity(
  entity?: DeviceEntity | null
): Device | null {
  if (!entity) {
    return null
  }

  return {
    ...entity,
    registerTime: toInstant(entity.registerTime),
    lastUpload: entity.lastUpload ? toInstant(entity.lastUpload) : undefined,
  }
}
