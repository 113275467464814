export const AutoLogout = () => import('../../components/AutoLogout.vue' /* webpackChunkName: "components/auto-logout" */).then(c => wrapFunctional(c.default || c))
export const CalendarDay = () => import('../../components/CalendarDay.vue' /* webpackChunkName: "components/calendar-day" */).then(c => wrapFunctional(c.default || c))
export const ConfirmButton = () => import('../../components/ConfirmButton.vue' /* webpackChunkName: "components/confirm-button" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../../components/ConfirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const EventsSummary = () => import('../../components/EventsSummary.vue' /* webpackChunkName: "components/events-summary" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LoadingDialog = () => import('../../components/LoadingDialog.vue' /* webpackChunkName: "components/loading-dialog" */).then(c => wrapFunctional(c.default || c))
export const PatientList = () => import('../../components/PatientList.vue' /* webpackChunkName: "components/patient-list" */).then(c => wrapFunctional(c.default || c))
export const SiteGroupList = () => import('../../components/SiteGroupList.vue' /* webpackChunkName: "components/site-group-list" */).then(c => wrapFunctional(c.default || c))
export const SiteGroupUserList = () => import('../../components/SiteGroupUserList.vue' /* webpackChunkName: "components/site-group-user-list" */).then(c => wrapFunctional(c.default || c))
export const SiteList = () => import('../../components/SiteList.vue' /* webpackChunkName: "components/site-list" */).then(c => wrapFunctional(c.default || c))
export const SiteUserList = () => import('../../components/SiteUserList.vue' /* webpackChunkName: "components/site-user-list" */).then(c => wrapFunctional(c.default || c))
export const UserList = () => import('../../components/UserList.vue' /* webpackChunkName: "components/user-list" */).then(c => wrapFunctional(c.default || c))
export const ChartsChartLegend = () => import('../../components/charts/chartLegend.vue' /* webpackChunkName: "components/charts-chart-legend" */).then(c => wrapFunctional(c.default || c))
export const ChartsSivaBarChart = () => import('../../components/charts/sivaBarChart.vue' /* webpackChunkName: "components/charts-siva-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartsSivaCoughChart = () => import('../../components/charts/sivaCoughChart.vue' /* webpackChunkName: "components/charts-siva-cough-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartsSivaLineChart = () => import('../../components/charts/sivaLineChart.vue' /* webpackChunkName: "components/charts-siva-line-chart" */).then(c => wrapFunctional(c.default || c))
export const CommonsButton = () => import('../../components/commons/Button.vue' /* webpackChunkName: "components/commons-button" */).then(c => wrapFunctional(c.default || c))
export const CommonsCounter = () => import('../../components/commons/Counter.vue' /* webpackChunkName: "components/commons-counter" */).then(c => wrapFunctional(c.default || c))
export const CommonsRaw = () => import('../../components/commons/Raw.vue' /* webpackChunkName: "components/commons-raw" */).then(c => wrapFunctional(c.default || c))
export const CommonsUserIcon = () => import('../../components/commons/UserIcon.vue' /* webpackChunkName: "components/commons-user-icon" */).then(c => wrapFunctional(c.default || c))
export const HeadersAdminMenu = () => import('../../components/headers/AdminMenu.vue' /* webpackChunkName: "components/headers-admin-menu" */).then(c => wrapFunctional(c.default || c))
export const HeadersHeader = () => import('../../components/headers/Header.vue' /* webpackChunkName: "components/headers-header" */).then(c => wrapFunctional(c.default || c))
export const HeadersMenuItemLink = () => import('../../components/headers/MenuItemLink.vue' /* webpackChunkName: "components/headers-menu-item-link" */).then(c => wrapFunctional(c.default || c))
export const HeadersPatientMenu = () => import('../../components/headers/PatientMenu.vue' /* webpackChunkName: "components/headers-patient-menu" */).then(c => wrapFunctional(c.default || c))
export const HeadersSiteGroupUserMenu = () => import('../../components/headers/SiteGroupUserMenu.vue' /* webpackChunkName: "components/headers-site-group-user-menu" */).then(c => wrapFunctional(c.default || c))
export const HeadersSiteUserMenu = () => import('../../components/headers/SiteUserMenu.vue' /* webpackChunkName: "components/headers-site-user-menu" */).then(c => wrapFunctional(c.default || c))
export const HeadersUserMenu = () => import('../../components/headers/UserMenu.vue' /* webpackChunkName: "components/headers-user-menu" */).then(c => wrapFunctional(c.default || c))
export const TablesAdherenceRatioEntry = () => import('../../components/tables/AdherenceRatioEntry.vue' /* webpackChunkName: "components/tables-adherence-ratio-entry" */).then(c => wrapFunctional(c.default || c))
export const TablesNumberWithThresholdEntry = () => import('../../components/tables/NumberWithThresholdEntry.vue' /* webpackChunkName: "components/tables-number-with-threshold-entry" */).then(c => wrapFunctional(c.default || c))
export const IconsAdd = () => import('../../components/icons/Add.vue' /* webpackChunkName: "components/icons-add" */).then(c => wrapFunctional(c.default || c))
export const IconsAddPerson = () => import('../../components/icons/AddPerson.vue' /* webpackChunkName: "components/icons-add-person" */).then(c => wrapFunctional(c.default || c))
export const IconsExternalLink = () => import('../../components/icons/ExternalLink.vue' /* webpackChunkName: "components/icons-external-link" */).then(c => wrapFunctional(c.default || c))
export const PatientsDetailedDeviceStatus = () => import('../../components/patients/DetailedDeviceStatus.vue' /* webpackChunkName: "components/patients-detailed-device-status" */).then(c => wrapFunctional(c.default || c))
export const PatientsCoughMonitorLog = () => import('../../components/patients/coughMonitorLog.vue' /* webpackChunkName: "components/patients-cough-monitor-log" */).then(c => wrapFunctional(c.default || c))
export const PatientsHistory = () => import('../../components/patients/history.vue' /* webpackChunkName: "components/patients-history" */).then(c => wrapFunctional(c.default || c))
export const PatientsInfo = () => import('../../components/patients/info.vue' /* webpackChunkName: "components/patients-info" */).then(c => wrapFunctional(c.default || c))
export const PatientsMannitolChallenge = () => import('../../components/patients/mannitolChallenge.vue' /* webpackChunkName: "components/patients-mannitol-challenge" */).then(c => wrapFunctional(c.default || c))
export const PatientsStudyPhaseLog = () => import('../../components/patients/studyPhaseLog.vue' /* webpackChunkName: "components/patients-study-phase-log" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
