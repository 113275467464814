import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState, UserQuery } from '~/store'
import { toInstant } from './utils'

export interface CoughEntity {
  since: string
  size: number
  confidence: number
  confidenceRaw: number
  worn: number
  rest: number
}

export interface CoughInfo {
  since: Instant
  size: number
  confidence: number
  confidenceRaw: number
  worn: number
  rest: number
}

export const state = () => ({ coughs: [] as Array<CoughInfo> })

export type CoughState = ReturnType<typeof state>

export const mutations: MutationTree<CoughState> = {
  FETCH_COUGHS(state, data: CoughEntity[]) {
    state.coughs = data.map((e: CoughEntity): CoughInfo => {
      return {
        ...e,
        since: toInstant(e.since),
      }
    })
  },
}

export const actions: ActionTree<CoughState, RootState> = {
  async fetchCoughs({ commit }, query: UserQuery) {
    const coughData = await this.$axios.$get<CoughEntity[]>(
      `/v1/patients/${query.uid}/coughs`,
      {
        params: {
          since: query.since?.toString(),
          until: query.until?.toString(),
          interval: query.interval,
        },
      }
    )
    commit('FETCH_COUGHS', coughData)
  },
}
