
import Vue from 'vue'
import AutoLogout from '~/components/AutoLogout.vue'
import ConfirmDialog from '~/components/ConfirmDialog.vue'
import SivaHeader from '~/components/headers/Header.vue'

interface Data {
  newVersion: string | undefined
  oldVersion: string | undefined
}

export default Vue.extend({
  components: {
    AutoLogout,
    SivaHeader,
    ConfirmDialog,
  },
  data(): Data {
    return {
      newVersion: undefined,
      oldVersion: undefined,
    }
  },
  head() {
    return {
      htmlAttrs: {
        class: 'bg-gray-100',
      },
      bodyAttrs: {
        class: 'bg-gray-100 font-sans leading-normal tracking-normal',
      },
    }
  },
  computed: {
    isUpdated(): boolean {
      if (this.oldVersion) {
        return this.oldVersion !== this.newVersion
      } else {
        return false
      }
    },
  },
  created() {
    this.newVersion = this.$config.version
    this.oldVersion = this.$cookies.get('dashboard-version')
    this.$cookies.set('dashboard-version', this.newVersion, {
      maxAge: 400 * 60 * 60 * 1000,
      path: '/',
    })
  },
  methods: {
    close() {
      // do nothing
    },
    confirm() {
      this.oldVersion = this.newVersion
    },
  },
})
