import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c2a88bc0 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _8338d636 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1fde3c16 = () => interopDefault(import('../pages/events.vue' /* webpackChunkName: "pages/events" */))
const _9e165d02 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _d8e498d8 = () => interopDefault(import('../pages/mannitol-challenges.vue' /* webpackChunkName: "pages/mannitol-challenges" */))
const _cbbb89d2 = () => interopDefault(import('../pages/mannitol-challenges/index.vue' /* webpackChunkName: "pages/mannitol-challenges/index" */))
const _7a62173f = () => interopDefault(import('../pages/mannitol-challenges/_id.vue' /* webpackChunkName: "pages/mannitol-challenges/_id" */))
const _fae2ad6a = () => interopDefault(import('../pages/patients.vue' /* webpackChunkName: "pages/patients" */))
const _706bcb8e = () => interopDefault(import('../pages/patients/index.vue' /* webpackChunkName: "pages/patients/index" */))
const _f8ada348 = () => interopDefault(import('../pages/patients/new.vue' /* webpackChunkName: "pages/patients/new" */))
const _238f210e = () => interopDefault(import('../pages/patients/_id/index.vue' /* webpackChunkName: "pages/patients/_id/index" */))
const _55064640 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _6189921e = () => interopDefault(import('../pages/site-group-users.vue' /* webpackChunkName: "pages/site-group-users" */))
const _aff5f698 = () => interopDefault(import('../pages/site-group-users/index.vue' /* webpackChunkName: "pages/site-group-users/index" */))
const _666a7902 = () => interopDefault(import('../pages/site-group-users/new.vue' /* webpackChunkName: "pages/site-group-users/new" */))
const _52b4a41c = () => interopDefault(import('../pages/site-group-users/_id.vue' /* webpackChunkName: "pages/site-group-users/_id" */))
const _a2981226 = () => interopDefault(import('../pages/site-groups.vue' /* webpackChunkName: "pages/site-groups" */))
const _335650a0 = () => interopDefault(import('../pages/site-groups/index.vue' /* webpackChunkName: "pages/site-groups/index" */))
const _5b4adafe = () => interopDefault(import('../pages/site-groups/new.vue' /* webpackChunkName: "pages/site-groups/new" */))
const _47950618 = () => interopDefault(import('../pages/site-groups/_id.vue' /* webpackChunkName: "pages/site-groups/_id" */))
const _b6851802 = () => interopDefault(import('../pages/site-users.vue' /* webpackChunkName: "pages/site-users" */))
const _d686817c = () => interopDefault(import('../pages/site-users/index.vue' /* webpackChunkName: "pages/site-users/index" */))
const _074b7f10 = () => interopDefault(import('../pages/site-users/new.vue' /* webpackChunkName: "pages/site-users/new" */))
const _18d4abac = () => interopDefault(import('../pages/site-users/_id.vue' /* webpackChunkName: "pages/site-users/_id" */))
const _60716dc2 = () => interopDefault(import('../pages/sites.vue' /* webpackChunkName: "pages/sites" */))
const _7dcb873c = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _4125c430 = () => interopDefault(import('../pages/sites/new.vue' /* webpackChunkName: "pages/sites/new" */))
const _2d6fef4a = () => interopDefault(import('../pages/sites/_id.vue' /* webpackChunkName: "pages/sites/_id" */))
const _03cd6f1b = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _9c5a1544 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _05bab746 = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _556eead0 = () => interopDefault(import('../pages/auth/action.vue' /* webpackChunkName: "pages/auth/action" */))
const _6940eed2 = () => interopDefault(import('../pages/auth/recoveremail.vue' /* webpackChunkName: "pages/auth/recoveremail" */))
const _2bc93b40 = () => interopDefault(import('../pages/auth/resetpassword.vue' /* webpackChunkName: "pages/auth/resetpassword" */))
const _37becc19 = () => interopDefault(import('../pages/auth/verifyemail.vue' /* webpackChunkName: "pages/auth/verifyemail" */))
const _5be2ebc0 = () => interopDefault(import('../pages/consents/privacy-policy.vue' /* webpackChunkName: "pages/consents/privacy-policy" */))
const _db71dee4 = () => interopDefault(import('../pages/consents/terms-of-service.vue' /* webpackChunkName: "pages/consents/terms-of-service" */))
const _57336338 = () => interopDefault(import('../pages/login/emailverification.vue' /* webpackChunkName: "pages/login/emailverification" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _c2a88bc0,
    name: "about"
  }, {
    path: "/de",
    component: _8338d636,
    name: "index___de"
  }, {
    path: "/en",
    component: _8338d636,
    name: "index___en"
  }, {
    path: "/events",
    component: _1fde3c16,
    name: "events"
  }, {
    path: "/login",
    component: _9e165d02,
    name: "login"
  }, {
    path: "/mannitol-challenges",
    component: _d8e498d8,
    children: [{
      path: "",
      component: _cbbb89d2,
      name: "mannitol-challenges"
    }, {
      path: ":id",
      component: _7a62173f,
      name: "mannitol-challenges-id"
    }]
  }, {
    path: "/patients",
    component: _fae2ad6a,
    children: [{
      path: "",
      component: _706bcb8e,
      name: "patients"
    }, {
      path: "new",
      component: _f8ada348,
      name: "patients-new"
    }, {
      path: ":id",
      component: _238f210e,
      name: "patients-id"
    }]
  }, {
    path: "/settings",
    component: _55064640,
    name: "settings"
  }, {
    path: "/site-group-users",
    component: _6189921e,
    children: [{
      path: "",
      component: _aff5f698,
      name: "site-group-users"
    }, {
      path: "new",
      component: _666a7902,
      name: "site-group-users-new"
    }, {
      path: ":id",
      component: _52b4a41c,
      name: "site-group-users-id"
    }]
  }, {
    path: "/site-groups",
    component: _a2981226,
    children: [{
      path: "",
      component: _335650a0,
      name: "site-groups"
    }, {
      path: "new",
      component: _5b4adafe,
      name: "site-groups-new"
    }, {
      path: ":id",
      component: _47950618,
      name: "site-groups-id"
    }]
  }, {
    path: "/site-users",
    component: _b6851802,
    children: [{
      path: "",
      component: _d686817c,
      name: "site-users"
    }, {
      path: "new",
      component: _074b7f10,
      name: "site-users-new"
    }, {
      path: ":id",
      component: _18d4abac,
      name: "site-users-id"
    }]
  }, {
    path: "/sites",
    component: _60716dc2,
    children: [{
      path: "",
      component: _7dcb873c,
      name: "sites"
    }, {
      path: "new",
      component: _4125c430,
      name: "sites-new"
    }, {
      path: ":id",
      component: _2d6fef4a,
      name: "sites-id"
    }]
  }, {
    path: "/users",
    component: _03cd6f1b,
    children: [{
      path: "",
      component: _9c5a1544,
      name: "users"
    }, {
      path: ":id",
      component: _05bab746,
      name: "users-id"
    }]
  }, {
    path: "/auth/action",
    component: _556eead0,
    name: "auth-action"
  }, {
    path: "/auth/recoveremail",
    component: _6940eed2,
    name: "auth-recoveremail"
  }, {
    path: "/auth/resetpassword",
    component: _2bc93b40,
    name: "auth-resetpassword"
  }, {
    path: "/auth/verifyemail",
    component: _37becc19,
    name: "auth-verifyemail"
  }, {
    path: "/consents/privacy-policy",
    component: _5be2ebc0,
    name: "consents-privacy-policy"
  }, {
    path: "/consents/terms-of-service",
    component: _db71dee4,
    name: "consents-terms-of-service"
  }, {
    path: "/de/about",
    component: _c2a88bc0,
    name: "about___de"
  }, {
    path: "/de/events",
    component: _1fde3c16,
    name: "events___de"
  }, {
    path: "/de/login",
    component: _9e165d02,
    name: "login___de"
  }, {
    path: "/de/mannitol-challenges",
    component: _d8e498d8,
    children: [{
      path: "",
      component: _cbbb89d2,
      name: "mannitol-challenges___de"
    }, {
      path: ":id",
      component: _7a62173f,
      name: "mannitol-challenges-id___de"
    }]
  }, {
    path: "/de/patients",
    component: _fae2ad6a,
    children: [{
      path: "",
      component: _706bcb8e,
      name: "patients___de"
    }, {
      path: "new",
      component: _f8ada348,
      name: "patients-new___de"
    }, {
      path: ":id",
      component: _238f210e,
      name: "patients-id___de"
    }]
  }, {
    path: "/de/settings",
    component: _55064640,
    name: "settings___de"
  }, {
    path: "/de/site-group-users",
    component: _6189921e,
    children: [{
      path: "",
      component: _aff5f698,
      name: "site-group-users___de"
    }, {
      path: "new",
      component: _666a7902,
      name: "site-group-users-new___de"
    }, {
      path: ":id",
      component: _52b4a41c,
      name: "site-group-users-id___de"
    }]
  }, {
    path: "/de/site-groups",
    component: _a2981226,
    children: [{
      path: "",
      component: _335650a0,
      name: "site-groups___de"
    }, {
      path: "new",
      component: _5b4adafe,
      name: "site-groups-new___de"
    }, {
      path: ":id",
      component: _47950618,
      name: "site-groups-id___de"
    }]
  }, {
    path: "/de/site-users",
    component: _b6851802,
    children: [{
      path: "",
      component: _d686817c,
      name: "site-users___de"
    }, {
      path: "new",
      component: _074b7f10,
      name: "site-users-new___de"
    }, {
      path: ":id",
      component: _18d4abac,
      name: "site-users-id___de"
    }]
  }, {
    path: "/de/sites",
    component: _60716dc2,
    children: [{
      path: "",
      component: _7dcb873c,
      name: "sites___de"
    }, {
      path: "new",
      component: _4125c430,
      name: "sites-new___de"
    }, {
      path: ":id",
      component: _2d6fef4a,
      name: "sites-id___de"
    }]
  }, {
    path: "/de/users",
    component: _03cd6f1b,
    children: [{
      path: "",
      component: _9c5a1544,
      name: "users___de"
    }, {
      path: ":id",
      component: _05bab746,
      name: "users-id___de"
    }]
  }, {
    path: "/en/about",
    component: _c2a88bc0,
    name: "about___en"
  }, {
    path: "/en/events",
    component: _1fde3c16,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _9e165d02,
    name: "login___en"
  }, {
    path: "/en/mannitol-challenges",
    component: _d8e498d8,
    children: [{
      path: "",
      component: _cbbb89d2,
      name: "mannitol-challenges___en"
    }, {
      path: ":id",
      component: _7a62173f,
      name: "mannitol-challenges-id___en"
    }]
  }, {
    path: "/en/patients",
    component: _fae2ad6a,
    children: [{
      path: "",
      component: _706bcb8e,
      name: "patients___en"
    }, {
      path: "new",
      component: _f8ada348,
      name: "patients-new___en"
    }, {
      path: ":id",
      component: _238f210e,
      name: "patients-id___en"
    }]
  }, {
    path: "/en/settings",
    component: _55064640,
    name: "settings___en"
  }, {
    path: "/en/site-group-users",
    component: _6189921e,
    children: [{
      path: "",
      component: _aff5f698,
      name: "site-group-users___en"
    }, {
      path: "new",
      component: _666a7902,
      name: "site-group-users-new___en"
    }, {
      path: ":id",
      component: _52b4a41c,
      name: "site-group-users-id___en"
    }]
  }, {
    path: "/en/site-groups",
    component: _a2981226,
    children: [{
      path: "",
      component: _335650a0,
      name: "site-groups___en"
    }, {
      path: "new",
      component: _5b4adafe,
      name: "site-groups-new___en"
    }, {
      path: ":id",
      component: _47950618,
      name: "site-groups-id___en"
    }]
  }, {
    path: "/en/site-users",
    component: _b6851802,
    children: [{
      path: "",
      component: _d686817c,
      name: "site-users___en"
    }, {
      path: "new",
      component: _074b7f10,
      name: "site-users-new___en"
    }, {
      path: ":id",
      component: _18d4abac,
      name: "site-users-id___en"
    }]
  }, {
    path: "/en/sites",
    component: _60716dc2,
    children: [{
      path: "",
      component: _7dcb873c,
      name: "sites___en"
    }, {
      path: "new",
      component: _4125c430,
      name: "sites-new___en"
    }, {
      path: ":id",
      component: _2d6fef4a,
      name: "sites-id___en"
    }]
  }, {
    path: "/en/users",
    component: _03cd6f1b,
    children: [{
      path: "",
      component: _9c5a1544,
      name: "users___en"
    }, {
      path: ":id",
      component: _05bab746,
      name: "users-id___en"
    }]
  }, {
    path: "/login/emailverification",
    component: _57336338,
    name: "login-emailverification"
  }, {
    path: "/de/auth/action",
    component: _556eead0,
    name: "auth-action___de"
  }, {
    path: "/de/auth/recoveremail",
    component: _6940eed2,
    name: "auth-recoveremail___de"
  }, {
    path: "/de/auth/resetpassword",
    component: _2bc93b40,
    name: "auth-resetpassword___de"
  }, {
    path: "/de/auth/verifyemail",
    component: _37becc19,
    name: "auth-verifyemail___de"
  }, {
    path: "/de/consents/privacy-policy",
    component: _5be2ebc0,
    name: "consents-privacy-policy___de"
  }, {
    path: "/de/consents/terms-of-service",
    component: _db71dee4,
    name: "consents-terms-of-service___de"
  }, {
    path: "/de/login/emailverification",
    component: _57336338,
    name: "login-emailverification___de"
  }, {
    path: "/en/auth/action",
    component: _556eead0,
    name: "auth-action___en"
  }, {
    path: "/en/auth/recoveremail",
    component: _6940eed2,
    name: "auth-recoveremail___en"
  }, {
    path: "/en/auth/resetpassword",
    component: _2bc93b40,
    name: "auth-resetpassword___en"
  }, {
    path: "/en/auth/verifyemail",
    component: _37becc19,
    name: "auth-verifyemail___en"
  }, {
    path: "/en/consents/privacy-policy",
    component: _5be2ebc0,
    name: "consents-privacy-policy___en"
  }, {
    path: "/en/consents/terms-of-service",
    component: _db71dee4,
    name: "consents-terms-of-service___en"
  }, {
    path: "/en/login/emailverification",
    component: _57336338,
    name: "login-emailverification___en"
  }, {
    path: "/",
    component: _8338d636,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
