
import Vue from 'vue'
import { AuthGetters, authStore, Claim } from '~/store/auth'
import { SiteGroupUserState } from '~/store/siteGroupUser'
import MenuItemLink from './MenuItemLink.vue'

export default Vue.extend({
  components: {
    MenuItemLink,
  },
  data() {
    const id = this.$store.getters[authStore(AuthGetters.getId)]
    this.$store.dispatch('siteGroupUser/fetchSiteGroupUsers', id)
    return {}
  },
  computed: {
    siteGroupId(): string {
      const users = (this.$store.state.siteGroupUser as SiteGroupUserState)
        .siteGroupUsers
      return users && users.length ? users[0].siteGroup.id ?? '' : ''
    },
    hasMannitolOperator(): boolean {
      return this.$store.getters[authStore(AuthGetters.hasOneClaim)](
        Claim.MannitolOperator
      )
    },
  },
})
