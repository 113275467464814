
import Vue from 'vue'
import { AuthGetters, authStore, Claim } from '~/store/auth'
import { SiteUserState } from '~/store/siteUser'
import MenuItemLink from './MenuItemLink.vue'

export default Vue.extend({
  components: {
    MenuItemLink,
  },
  data() {
    const id = this.$store.getters[authStore(AuthGetters.getId)]
    this.$store.dispatch('siteUser/fetchSiteUser', id)
    return {}
  },
  computed: {
    siteId(): string {
      return (this.$store.state.siteUser as SiteUserState).user?.site?.id ?? ''
    },
    hasMannitolOperator(): boolean {
      return this.$store.getters[authStore(AuthGetters.hasOneClaim)](
        Claim.MannitolOperator
      )
    },
  },
})
