import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState, UserQuery } from '~/store'
import { toInstant } from './utils'

export enum FunctionTestResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  NO_DATA = 'NO_DATA',
}

export interface FunctionTestEntity {
  since: string
  size: number
  result: FunctionTestResult
}

export interface FunctionTestInfo extends Omit<FunctionTestEntity, 'since'> {
  since: Instant
}

export const state = () => ({
  functionTests: [] as Array<FunctionTestInfo>,
})

export type FunctionTestState = ReturnType<typeof state>

export const mutations: MutationTree<FunctionTestState> = {
  FETCH_FUNCTION_TESTS(state, data: FunctionTestEntity[]) {
    state.functionTests = data.map((e) => ({
      ...e,
      since: toInstant(e.since),
    }))
  },
}

export const actions: ActionTree<FunctionTestState, RootState> = {
  async fetchFunctionTests({ commit }, query: UserQuery) {
    const tests = await this.$axios.$get<FunctionTestEntity[]>(
      `/v1/patients/${query.uid}/function-tests`,
      {
        params: {
          since: query.since?.toString(),
          until: query.until?.toString(),
          interval: query.interval,
        },
      }
    )
    commit('FETCH_FUNCTION_TESTS', tests)
  },
  clear({ commit }) {
    commit('FETCH_FUNCTION_TESTS', [])
  },
}
