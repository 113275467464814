// SIVA Steps Data
import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState, UserQuery } from '~/store'
import { toInstant } from './utils'

export interface StepsEntity {
  since: string
  steps: number
}

export interface StepsInfo extends Omit<StepsEntity, 'since'> {
  since: Instant
}

export const state = () => ({
  infos: [] as Array<StepsInfo>,
})

export type StepsState = ReturnType<typeof state>

export const mutations: MutationTree<StepsState> = {
  FETCH_STEPS(state, data: StepsEntity[]) {
    state.infos = data.map((e) => ({
      ...e,
      since: toInstant(e.since),
    }))
  },
}

export const actions: ActionTree<StepsState, RootState> = {
  async fetchSteps({ commit }, query: UserQuery) {
    const stepsData = await this.$axios.$get<StepsEntity[]>(
      `/v1/patients/${query.uid}/steps`,
      {
        params: {
          since: query.since?.toString(),
          until: query.until?.toString(),
          interval: query.interval,
        },
      }
    )
    commit('FETCH_STEPS', stepsData)
  },
}
