// SIVA Meals Data
import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState, UserQuery } from '~/store'
import { toInstant } from './utils'

export interface MealEntity {
  since: string
  size: number
}

export interface MealInfo extends Omit<MealEntity, 'since'> {
  since: Instant
}

export const state = () => ({
  meals: [] as Array<MealInfo>,
})

export type MealState = ReturnType<typeof state>

export const mutations: MutationTree<MealState> = {
  FETCH_MEALS(state, data: MealEntity[]) {
    state.meals = data.map((e) => ({
      ...e,
      since: toInstant(e.since),
    }))
  },
}

export const actions: ActionTree<MealState, RootState> = {
  async fetchMeals({ commit }, query: UserQuery) {
    const meals = await this.$axios.$get<MealEntity[]>(
      `/v1/patients/${query.uid}/meals`,
      {
        params: {
          since: query.since?.toString(),
          until: query.until?.toString(),
          interval: query.interval,
        },
      }
    )
    commit('FETCH_MEALS', meals)
  },
  clear({ commit }) {
    commit('FETCH_MEALS', [])
  },
}
