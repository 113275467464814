const middleware = {}

middleware['admin'] = require('../middleware/admin.ts')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['claimAuth'] = require('../middleware/claimAuth.ts')
middleware['claimAuth'] = middleware['claimAuth'].default || middleware['claimAuth']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.ts')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['roleAuth'] = require('../middleware/roleAuth.ts')
middleware['roleAuth'] = middleware['roleAuth'].default || middleware['roleAuth']

export default middleware
