import { Middleware } from '@nuxt/types'
import { AuthGetters, authStore } from '~/store/auth'

const middleware: Middleware = ({ store, route, error }) => {
  // Get authorizations for matched routes (with children routes too)
  const claims = route.meta?.at(-1).auth?.claims ?? []
  if (claims.length === 0) {
    return
  }

  if (!store.getters[authStore(AuthGetters.hasOneClaim)](claims)) {
    error({ statusCode: 403, message: 'Access denied' })
  }
}

export default middleware
