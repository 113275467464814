import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'

export interface SiteGroup {
  id: string
  name: string
  siteIds: string[]
}

export interface SiteGroupEntity {
  id: string
  name: string
  siteIds: string[]
}

export const state = () => ({
  siteGroups: [] as Array<SiteGroup>,
})

export type SiteGroupState = ReturnType<typeof state>

export const mutations: MutationTree<SiteGroupState> = {
  FETCH_SITE_GROUPS(state, data: SiteGroupEntity[]) {
    state.siteGroups = data
      .map((user) => ({
        id: user.id,
        name: user.name,
        siteIds: user.siteIds,
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  },
}

export const actions: ActionTree<SiteGroupState, RootState> = {
  async fetchSiteGroups({ commit }) {
    const siteGroups = await this.$axios.$get<SiteGroupEntity[]>(
      `/v1/site-groups`
    )
    commit('FETCH_SITE_GROUPS', siteGroups)
  },
}
