import { Middleware } from '@nuxt/types'
import { AuthGetters, authStore } from '~/store/auth'

const authenticated: Middleware = ({ store, redirect, localePath }) => {
  // If the user is authenticated redirect to home page
  if (!store.getters[authStore(AuthGetters.isLoggedIn)]) {
    return redirect(localePath('/login'))
  }
}

export default authenticated
