import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { toInstant } from './utils'

export enum Actions {
  append = 'append',
  clear = 'clear',
  fetch = 'fetch',
}

export enum Mutations {
  APPEND = 'APPEND',
  CLEAR = 'CLEAR',
  FETCH = 'FETCH',
}

export interface MannitolChallengeEntity {
  start: string
  end: string
  doses: {
    start: string
    end: string
    dose: number
    coughs: number
  }[]
  cdr: number
}

export interface MannitolChallenge {
  start: Instant
  end: Instant
  doses: {
    start: Instant
    end: Instant
    dose: number
    coughs: number
  }[]
  cdr: number
}

export enum RawMannitolChallengeType {
  BEGIN = 'BEGIN_CHALLENGE',
  CANCEL = 'CANCEL',
  END = 'END_CHALLENGE',
  START_INHALATION = 'START_INHALATION',
  START_INTAKE = 'START_INTAKE',
  STOP_INHALATION = 'STOP_INHALATION',
}

export interface RawMannitolChallenge {
  time: Instant
  patientId: string
  sessionId: string
  dose: number
  type: RawMannitolChallengeType
}

export const state = () => ({
  challenges: [] as Array<MannitolChallenge>,
  raws: [] as Array<RawMannitolChallenge>,
})

export type MannitolChallengeState = ReturnType<typeof state>

export const mannitolStore = (method: Actions | Mutations) =>
  `mannitolChallenge/${method}`

export const mutations: MutationTree<MannitolChallengeState> = {
  [Mutations.FETCH](state, data: MannitolChallengeEntity[]) {
    state.challenges = data.map((e) => ({
      ...e,
      start: toInstant(e.start),
      end: toInstant(e.end),
      doses: e.doses.map((d) => ({
        ...d,
        start: toInstant(d.start),
        end: toInstant(d.end),
      })),
    }))
  },
  [Mutations.APPEND](state, data: RawMannitolChallenge) {
    state.raws.push(data)
  },
  [Mutations.CLEAR](state) {
    state.challenges.length = 0
    state.raws.length = 0
  },
}

export const actions: ActionTree<MannitolChallengeState, RootState> = {
  async [Actions.fetch]({ commit }, patientId: string) {
    const entries = await this.$axios.$get<MannitolChallengeEntity[]>(
      `/v1/patients/${patientId}/mannitol-challenges`
    )
    commit(Mutations.FETCH, entries)
  },
  async [Actions.append]({ commit }, payload: RawMannitolChallenge) {
    await this.$axios.$post<MannitolChallenge[]>(
      `/v1/patients/${payload.patientId}/mannitol-challenges`,
      payload
    )
    commit(Mutations.APPEND, payload)
  },
  [Actions.clear]({ commit }) {
    commit(Mutations.CLEAR)
  },
}
