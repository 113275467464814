
import Vue from 'vue'
import { authStore, AuthActions, AuthGetters } from '~/store/auth'
import MenuItemLink from './MenuItemLink.vue'
export default Vue.extend({
  components: {
    MenuItemLink,
  },
  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters[authStore(AuthGetters.isLoggedIn)]
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch(authStore(AuthActions.logout))
      this.$router.push(this.localePath('/'))
    },
  },
})
