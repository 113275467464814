import '@js-joda/timezone'

import { LocalDateTime, ZonedDateTime, ZoneId } from '@js-joda/core'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { Site, SiteEntity, toSiteModel } from './site'
import { DATETIME_FORMATTER } from './utils'

export interface Patient {
  id: string
  customId: string
  firstname: string
  lastname: string
  email?: string
  onboarding: ZonedDateTime
  site: Site
}

export interface PatientCredential {
  id: string
  email: string
  password: string
}

export interface PatientEntity {
  id: string
  customId: string
  firstname: string
  lastname: string
  email: string
  onboarding: string
  site: SiteEntity
}

export function getAnonymousPatientName(
  patient: Pick<Patient, 'id' | 'customId' | 'email'>,
  shortenOnFallback = false
): string {
  if (patient.customId) {
    return patient.customId
  }

  if (patient.email) {
    return patient.email.replace('@siva-health.com', '')
  }

  if (shortenOnFallback) {
    return patient.id.length > 8 ? patient.id.substring(0, 8) : patient.id
  }

  return patient.id
}

export const state = () => ({
  patients: [] as Array<Patient>,
  patient: {} as Patient,
})

export type PatientState = ReturnType<typeof state>

export function toPatientModel(entity: PatientEntity): Patient {
  return {
    id: entity.id,
    customId: entity.customId,
    firstname: entity.firstname,
    lastname: entity.lastname,
    email: entity.email,
    onboarding: LocalDateTime.parse(
      entity.onboarding,
      DATETIME_FORMATTER
    ).atZone(
      entity.site.timezone ? ZoneId.of(entity.site.timezone) : ZoneId.SYSTEM
    ),
    site: toSiteModel(entity.site),
  }
}

export const mutations: MutationTree<PatientState> = {
  FETCH_PATIENTS(state, data: PatientEntity[]) {
    state.patients = data
      .map(toPatientModel)
      .sort((a, b) =>
        (a.lastname + a.firstname).localeCompare(b.lastname + b.firstname)
      )
  },
  FETCH_PATIENT(state, patient: PatientEntity) {
    state.patient = toPatientModel(patient)
  },
  RESET_PATIENT(state) {
    state.patient = {} as Patient
  },
}

export const actions: ActionTree<PatientState, RootState> = {
  async fetchPatients({ commit }) {
    const patients = await this.$axios.$get<PatientEntity[]>(`/v1/patients`)
    commit('FETCH_PATIENTS', patients)
  },
  async fetchPatient({ commit }, pid) {
    const patient = await this.$axios.$get<PatientEntity>(`/v1/patients/${pid}`)
    commit('FETCH_PATIENT', patient)
  },
  resetPatient({ commit }) {
    commit('RESET_PATIENT')
  },
}

export const getters: GetterTree<PatientState, PatientState> = {
  getId: (state) => state.patient?.id,
  getTimezone: (state) => state.patient?.site?.timezone ?? ZoneId.SYSTEM,
}
