
import { AxiosError } from 'axios'
import Vue, { PropOptions } from 'vue'

interface SivaError {
  message: string
  statusCode: number
}

interface HttpError {
  message: string | string[]
}

export default Vue.extend({
  layout: 'error',
  props: {
    error: {
      type: Object,
      required: true,
    } as PropOptions<SivaError | AxiosError<HttpError>>,
  },
  data() {
    if ('response' in this.error && this.error.response) {
      return {
        errorMessage: Array.isArray(this.error.response.data.message)
          ? this.error.response.data.message.join('<br />')
          : this.error.response.data.message,
      }
    }

    return {
      errorMessage: '',
    }
  },
})
