import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { SiteGroupUserEntity } from './siteGroupUser'
import { SiteUserEntity } from './siteUser'

export enum Claim {
  Admin = 'admin',
  LabelWorker = 'label_worker',
  MlWorker = 'ml_worker',
  Patient = 'patient',
  ProductionTester = 'production_tester',
  SiteGroupUser = 'site_group_user',
  SiteUser = 'site_user',
  TaskWorker = 'task_worker',
  Wearable = 'wearable',
  Compliance = 'compliance-criterion',
  CoughConfidence = 'cough-confidence',
  CoughDiagram = 'cough-diagrams',
  CreatePatient = 'create-patient',
  DeanonymizedPatient = 'deanonymized-patient',
  DetailedDeviceStatus = 'detailed-device-status',
  FunctionTest = 'function-test',
  MannitolOperator = 'mannitol-operator',
  MannitolView = 'mannitol-view',
  Meal = 'meal',
  MeasuredAdherence = 'measured-adherence',
  QuestionnaireAdherence = 'questionnaire-adherence',
  SiteCreation = 'site-creation',
  StudyPhaseChange = 'study-phase-change',
  StudyPhaseView = 'study-phase-view',
  StudyPhasePause = 'study-phase-pause',
  SummaryDeviceStatus = 'summary-device-status',
  EventsView = 'events-view',
}

export enum Role {
  Admin = 'admin',
  SiteUser = 'site_user',
  Patient = 'patient',
  SiteGroupUser = 'site_group_user',
  Wearable = 'wearable',

  // Service Roles
  LabelWorker = 'label_worker',
  MlWorker = 'ml_worker',
  ProductionTester = 'production_tester',
  TaskWorker = 'task_worker',
}

export interface UserEntity {
  id: string
  roles: string[]
  roleClaims: string[]
  userClaims: string[]
}

export interface ClaimEntity {
  name: string
  description: string
}

export interface RoleEntity {
  name: string
  displayName: string
}

export interface UserInfo {
  claims: Set<Claim>
  claimsRole: Set<Claim>
  claimsUser: Set<Claim>
  email: string
  emailVerified: boolean
  roles: Set<Role>
  siteId?: string
  siteGroupId?: string
  uid: string
}

export interface AuthStateChangedActionPayload {
  authUser: {
    displayName: string
    email: string
    emailVerified: boolean
    isAnonymous: boolean
    phoneNumber: string | null
    photoURL: string | null
    providerData: {
      providerId: string
      uid: string
      displayName: string | null
      email: string | null
      phoneNumber: string | null
      photoURL: string | null
    }[]
    providerId: string
    refreshToken: string
    tenantId: string | null
    uid: string

    delete(): Promise<void>
    getIdToken(): Promise<string>
    getIdTokenResult(): Promise<unknown>
    reload(): Promise<unknown>
  } | null
  claims: unknown
}

export interface FetchUserPayload {
  uid: string
  email: string
  emailVerified: boolean
}

export interface LoginPayload {
  email: string
  password: string
}

export const state = () => ({
  user: null as UserInfo | null,
  availableClaims: [] as ClaimEntity[],
  availableRoles: [] as RoleEntity[],
})

export type UserState = ReturnType<typeof state>

export const enum AuthActions {
  onAuthStateChangedAction = 'onAuthStateChangedAction',
  fetchUser = 'fetchUser',
  fetchClaims = 'fetchClaims',
  fetchRoles = 'fetchRoles',
  login = 'login',
  logout = 'logout',
}

export enum AuthMutations {
  RESET_STORE = 'RESET_STORE',
  SET_AUTH_USER = 'SET_AUTH_USER',
  SET_CLAIMS = 'SET_CLAIMS',
  SET_ROLES = 'SET_ROLES',
}

export enum AuthGetters {
  canChangeStudyPhase = 'canChangeStudyPhase',
  canViewStudyPhase = 'canViewStudyPhase',
  getId = 'getId',
  getSiteId = 'getSiteId',
  getSiteGroupId = 'getSiteGroupId',
  getUsername = 'getUsername',
  hasOneClaim = 'hasOneClaim',
  hasOneRole = 'hasOneRole',
  hasUser = 'hasUser',
  isAdmin = 'isAdmin',
  isLoggedIn = 'isLoggedIn',
  isPatient = 'isPatient',
  isSiteGroupUser = 'isSiteGroupUser',
  isSiteUser = 'isSiteUser',
}

export const authStore = (method: AuthActions | AuthMutations | AuthGetters) =>
  `auth/${method}`

export const mutations: MutationTree<UserState> = {
  [AuthMutations.RESET_STORE]: (state: UserState) => {
    state.user = null
  },

  [AuthMutations.SET_AUTH_USER]: (state: UserState, authUser: UserInfo) => {
    state.user = {
      ...authUser,
    }
  },

  [AuthMutations.SET_CLAIMS]: (state: UserState, claims: ClaimEntity[]) => {
    claims.sort((a, b) => a.name.localeCompare(b.name))
    state.availableClaims = [...claims]
  },

  [AuthMutations.SET_ROLES]: (state: UserState, roles: RoleEntity[]) => {
    roles.sort((a, b) => a.name.localeCompare(b.name))
    state.availableRoles = [...roles]
  },
}

let interceptor: number

export const actions: ActionTree<UserState, UserState> = {
  async [AuthActions.onAuthStateChangedAction](
    { commit, dispatch },
    { authUser }: AuthStateChangedActionPayload
  ) {
    if (!authUser) {
      commit(AuthMutations.RESET_STORE)
      // Remove axios interceptor for authorization token
      this.$axios.interceptors.request.eject(interceptor)
    } else if (authUser && authUser.getIdToken) {
      // Add axios interceptor to ask for last or new idToken
      interceptor = this.$axios.interceptors.request.use(async (config) => {
        const idToken = await authUser.getIdToken()
        if (idToken) {
          config.headers.Authorization = `Bearer ${idToken}`
        }
        return config
      })

      // Retrieve claims from backend
      await dispatch(AuthActions.fetchUser, <FetchUserPayload>{
        uid: authUser.uid,
        email: authUser.email,
        emailVerified: authUser.emailVerified,
      })
    }
  },

  async [AuthActions.fetchUser](
    { commit, state },
    { uid, email, emailVerified }: FetchUserPayload
  ) {
    uid = uid ?? state.user?.uid
    if (!uid) {
      return
    }

    const user = await this.$axios.$get<UserEntity>(`v1/users/${uid}`)
    if (!user) {
      return
    }

    const claims = new Set([
      ...(user.userClaims ?? []),
      ...(user.roleClaims ?? []),
    ])

    const siteUserEntity = claims.has(Claim.SiteUser)
      ? await this.$axios.$get<SiteUserEntity>(`/v1/site-users/${uid}`, {
          // It's always successful, even if the user doesn't exist
          validateStatus: () => true,
        })
      : undefined

    const siteGroupUserEntity = claims.has(Claim.SiteGroupUser)
      ? await this.$axios.$get<SiteGroupUserEntity>(
          `/v1/site-group-users/${uid}`,
          {
            // It's always successful, even if the user doesn't exist
            validateStatus: () => true,
          }
        )
      : undefined

    commit(AuthMutations.SET_AUTH_USER, <UserInfo>{
      claims,
      claimsRole: new Set(user.roleClaims ?? []),
      claimsUser: new Set(user.userClaims ?? []),
      email: email ?? state.user?.email ?? '',
      emailVerified: emailVerified ?? state.user?.emailVerified ?? false,
      roles: new Set(user.roles ?? []),
      siteId: siteUserEntity?.site?.id,
      siteGroupId: siteGroupUserEntity?.siteGroup?.id,
      uid: user.id,
    })
  },

  async [AuthActions.fetchClaims]({ commit }) {
    const claims = await this.$axios.$get<ClaimEntity[]>('v1/claims')
    commit(AuthMutations.SET_CLAIMS, claims)
  },

  async [AuthActions.fetchRoles]({ commit }) {
    const roles = await this.$axios.$get<RoleEntity[]>('v1/roles')
    commit(AuthMutations.SET_ROLES, roles)
  },

  async [AuthActions.login](_, { email, password }: LoginPayload) {
    const { user } = await this.$fire.auth.signInWithEmailAndPassword(
      email,
      password
    )
    return user
  },

  async [AuthActions.logout]({ commit }) {
    await this.$fire.auth.signOut()
    commit(AuthMutations.RESET_STORE)
  },
}

export const getters: GetterTree<UserState, UserState> = {
  [AuthGetters.getId]: (state) => state.user?.uid,
  [AuthGetters.getSiteId]: (state) => state.user?.siteId,
  [AuthGetters.getSiteGroupId]: (state) => state.user?.siteGroupId,
  [AuthGetters.getUsername]: (state) => state.user?.email,
  [AuthGetters.hasUser]: (state) => state.user !== null,
  [AuthGetters.hasOneClaim]:
    (state, getters) => (claims: string | Claim | (string | Claim)[]) => {
      if (!getters.isLoggedIn) {
        return false
      }

      return Array.isArray(claims)
        ? claims.some((claim) => state.user?.claims.has(claim as Claim))
        : state.user?.claims.has(claims as Claim)
    },
  [AuthGetters.hasOneRole]:
    (state, getters) => (roles: string | Role | (string | Role)[]) => {
      if (!getters.isLoggedIn) {
        return false
      }

      return Array.isArray(roles)
        ? roles.some((role) => state.user?.roles.has(role as Role))
        : state.user?.roles.has(roles as Role)
    },
  [AuthGetters.isAdmin]: (_, getters) => getters.hasOneClaim(Claim.Admin),
  [AuthGetters.isLoggedIn]: (state) =>
    state.user !== null && state.user.emailVerified,
  [AuthGetters.isPatient]: (_, getters) => getters.hasOneClaim(Claim.Patient),
  [AuthGetters.isSiteGroupUser]: (_, getters) =>
    getters.hasOneClaim(Claim.SiteGroupUser),
  [AuthGetters.isSiteUser]: (_, getters) => getters.hasOneClaim(Claim.SiteUser),
  [AuthGetters.canViewStudyPhase]: (_, getters) =>
    getters.hasOneClaim([Claim.StudyPhaseView, Claim.StudyPhaseChange]),
  [AuthGetters.canChangeStudyPhase]: (_, getters) =>
    getters.hasOneClaim(Claim.StudyPhaseChange),
}
