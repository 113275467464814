import localec28654ec from '../../locales/en-US.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en-US.json","name":"English 🇺🇸🇬🇧"},{"code":"de","iso":"de-DE","file":"de-DE.json","name":"Deutsch 🇩🇪🇨🇭"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  baseURL: "http://localhost:3000",
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en-US.json","name":"English 🇺🇸🇬🇧"},{"code":"de","iso":"de-DE","file":"de-DE.json","name":"Deutsch 🇩🇪🇨🇭"}],
  localeCodes: ["en","de"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "l",
  19: "o",
  20: "c",
  21: "a",
  22: "l",
  23: "e",
  24: "s",
  25: "/",
  26: "e",
  27: "n",
  28: "-",
  29: "U",
  30: "S",
  31: ".",
  32: "j",
  33: "s",
  34: "o",
  35: "n",
  36: "\"",
  37: ",",
  38: "\"",
  39: "d",
  40: "e",
  41: "-",
  42: "D",
  43: "E",
  44: ".",
  45: "j",
  46: "s",
  47: "o",
  48: "n",
  49: "\"",
  50: ":",
  51: "\"",
  52: ".",
  53: ".",
  54: "/",
  55: "l",
  56: "o",
  57: "c",
  58: "a",
  59: "l",
  60: "e",
  61: "s",
  62: "/",
  63: "d",
  64: "e",
  65: "-",
  66: "D",
  67: "E",
  68: ".",
  69: "j",
  70: "s",
  71: "o",
  72: "n",
  73: "\"",
  74: "}",
}

export const localeMessages = {
  'en-US.json': () => Promise.resolve(localec28654ec),
  'de-DE.json': () => import('../../locales/de-DE.json' /* webpackChunkName: "lang-de-DE.json" */),
}
