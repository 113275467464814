import { Duration, Instant } from '@js-joda/core'
import type { Store } from 'vuex/types'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'

const SIZE_FOR_MEAN = 10

interface UpdatePayload {
  clientTimeStart: number
  clientTimeEnd: number
  serverTimeStart: number
  serverTimeEnd: number
}

interface MutationUpdatePayload {
  latency: number
  offset: number
}

export enum Actions {
  update = 'update',
}

export enum Mutations {
  UPDATE = 'UPDATE',
}

export enum Getters {
  latency = 'latency',
  offset = 'offset',
}

export const timeStore = (method: Actions | Mutations | Getters) =>
  `time/${method}`

export const now = (store: Store<any>): Instant =>
  store && store.getters
    ? Instant.now().plus(store.getters[timeStore(Getters.offset)])
    : Instant.now()

export const state = () => ({
  latencies: [] as number[],
  latencyMean: null as number | null,
  offsets: [] as number[],
  offsetMean: null as number | null,
})

export type TimeState = ReturnType<typeof state>

export const mutations: MutationTree<TimeState> = {
  [Mutations.UPDATE](state, data: MutationUpdatePayload) {
    if (data.offset) {
      state.offsets.push(data.offset)

      // Keep the last SIZE_FOR_MEAN entries
      if (state.offsets.length > SIZE_FOR_MEAN) {
        state.offsets.shift()
      }

      // Calculate the average mean of offsets
      state.offsetMean =
        state.offsets.reduce((a, b) => a + b, 0) / state.offsets.length
    }

    if (data.latency) {
      state.latencies.push(data.latency)

      // Keep the last SIZE_FOR_MEAN entries
      if (state.latencies.length > SIZE_FOR_MEAN) {
        state.latencies.shift()
      }

      // Calculate the average mean of latencies
      state.latencyMean =
        state.latencies.reduce((a, b) => a + b, 0) / state.latencies.length
    }
  },
}

export const actions: ActionTree<TimeState, RootState> = {
  [Actions.update](
    { commit },
    {
      clientTimeStart,
      clientTimeEnd,
      serverTimeStart,
      serverTimeEnd,
    }: UpdatePayload
  ) {
    // https://stackoverflow.com/questions/1638337/the-best-way-to-synchronize-client-side-javascript-clock-with-server-date

    const latency =
      clientTimeEnd - clientTimeStart - (serverTimeEnd - serverTimeStart)
    const offset =
      (serverTimeStart - clientTimeStart + (serverTimeEnd - clientTimeEnd)) / 2

    commit(Mutations.UPDATE, <MutationUpdatePayload>{ latency, offset })
  },
}

export const getters: GetterTree<TimeState, TimeState> = {
  [Getters.latency]: (state): Duration => {
    if (!state.latencyMean) {
      return Duration.ZERO
    }
    return Duration.ofNanos(Math.floor(state.latencyMean * 1e6))
  },
  [Getters.offset]: (state): Duration => {
    if (!state.offsetMean) {
      return Duration.ZERO
    }
    return Duration.ofNanos(Math.floor(state.offsetMean * 1e6))
  },
}
