import '@js-joda/timezone'

import { ZoneId } from '@js-joda/core'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'

export interface CreateSiteDto {
  country: string
  name: string
  siteGroupIds: string[]
  timezone: string
}

export interface Site {
  id: string
  country: string
  customId: string
  name: string
  patientCustomIdPattern: RegExp
  principalInvestigator: string
  siteGroupIds: string[]
  timezone: ZoneId
}

export interface SiteEntity {
  id: string
  country: string
  customId: string
  name: string
  patientCustomIdPattern: string
  principalInvestigator: string
  siteGroupIds: string[]
  timezone: string
}

export enum SiteGetters {
  findById = 'findById',
}

export enum SiteActions {
  createSite = 'createSite',
}

export const siteStore = (method: SiteActions | SiteGetters) => `site/${method}`

export const state = () => ({
  sites: [] as Array<Site>,
  site: {} as Site,
})

export type SiteState = ReturnType<typeof state>

export const mutations: MutationTree<SiteState> = {
  FETCH_SITE(state, data: SiteEntity) {
    // Update current site
    state.site = toSiteModel(data)

    // Update collection of sites
    state.sites = state.sites.filter((site) => site.id !== data.id)
    state.sites.push(state.site)
  },
  FETCH_SITES(state, data: SiteEntity[]) {
    state.sites = data.map(toSiteModel)
  },
}

export const actions: ActionTree<SiteState, RootState> = {
  async createSite({ commit }, dto: CreateSiteDto) {
    const newSiteEntity = await this.$axios.$post<SiteEntity>(`/v1/sites`, dto)
    commit('FETCH_SITE', newSiteEntity)
    return toSiteModel(newSiteEntity)
  },
  async fetchSite({ commit }, siteId) {
    const siteEntity = await this.$axios.$get<SiteEntity>(`/v1/sites/${siteId}`)
    commit('FETCH_SITE', siteEntity)
  },
  async fetchSites({ commit }) {
    const siteEntities = await this.$axios.$get<SiteEntity[]>(`/v1/sites`)
    commit('FETCH_SITES', siteEntities)
  },
  async fetchSitesByGroup({ commit }, siteGroupId) {
    const siteEntities = await this.$axios.$get<SiteEntity[]>(
      `/v1/site-groups/${siteGroupId}/sites`
    )
    commit('FETCH_SITES', siteEntities)
  },
}

export const getters: GetterTree<SiteState, SiteState> = {
  [SiteGetters.findById]: (state) => (id: string) => {
    return state.sites.find((site) => site.id === id)
  },
}

export const toSiteModel = (site: SiteEntity): Site => {
  return {
    id: site.id,
    country: site.country,
    customId: site.customId,
    name: site.name,
    patientCustomIdPattern: site.patientCustomIdPattern
      ? new RegExp(site.patientCustomIdPattern)
      : /^.+$/,
    principalInvestigator: site.principalInvestigator,
    siteGroupIds: site.siteGroupIds,
    timezone: ZoneId.of(site.timezone ?? 'Europe/Zurich'),
  }
}
