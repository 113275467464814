
import Vue, { PropOptions } from 'vue'

export default Vue.extend({
  props: {
    confirmText: {
      type: String,
      required: false,
      default: 'Confirm',
    } as PropOptions<string>,
    cancelText: {
      type: String,
      required: false,
      default: 'Cancel',
    } as PropOptions<string>,
    hideCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    } as PropOptions<boolean>,
  },
})
