import { LocalDate } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'

export interface PatientSummary {
  patientId: string
  customId: string
  firstname: string
  lastname: string
  site: string
}

export interface NewMannitolChallenge {
  patientId: string
  firstname: string
  lastname: string
  customId: string
  site: string
}
export interface StudyPhaseTransition {
  patientId: string
  firstname: string
  lastname: string
  customId: string
  site: string
  to: string
}

export interface EventsSummary {
  date: LocalDate
  patients: PatientSummary[]
  mannitolChallenges: NewMannitolChallenge[]
  studyPhaseTransitions: StudyPhaseTransition[]
  coughDataProcessed: number
}

export interface EventsSummaryDto extends Omit<EventsSummary, 'date'> {
  date: string
}

export interface EventsDto {
  events: EventsSummaryDto[]
  coughDataNotYetQueued: number
  coughDataQueued: number
}

export const state = () => ({
  events: [] as Array<EventsSummary>,
  coughDataNotYetQueued: 0,
  coughDataQueued: 0,
})

export type EventsState = ReturnType<typeof state>

export const mutations: MutationTree<EventsState> = {
  FETCH_EVENTS(state, eventsData: EventsDto) {
    state.events = eventsData.events.map((e) => ({
      ...e,
      date: LocalDate.parse(e.date),
    }))
    state.coughDataNotYetQueued = eventsData.coughDataNotYetQueued
    state.coughDataQueued = eventsData.coughDataQueued
  },
}

export const actions: ActionTree<EventsState, RootState> = {
  async fetchEvents({ commit }) {
    const eventsData = await this.$axios.$get<EventsDto[]>(`/v1/events`)
    commit('FETCH_EVENTS', eventsData)
  },
}
