
import Vue from 'vue'
import { AuthGetters, authStore, Claim } from '~/store/auth'
import MenuItemLink from './MenuItemLink.vue'

export default Vue.extend({
  components: {
    MenuItemLink,
  },
  computed: {
    hasEventsView(): boolean {
      return this.$store.getters[authStore(AuthGetters.hasOneClaim)](
        Claim.EventsView
      )
    },
    hasMannitolOperator(): boolean {
      return this.$store.getters[authStore(AuthGetters.hasOneClaim)](
        Claim.MannitolOperator
      )
    },
  },
})
