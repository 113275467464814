import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { toInstant } from './utils'

export interface DeviceConnectionEntity {
  id: number
  time: string
  deviceTime: string
  battery: number
  steps: number
  temperature: number
  humidity: number
  untransmittedRawChunks: number
  untransmittedEnvChunks: number
  untransmittedKeys: number
  worn: boolean
  onCharger: boolean
  functionTest: number
  storageUsed: number | null
}

export interface DeviceConnectionInfo {
  id: number
  time: Instant
  deviceTime: Instant
  battery: number
  steps: number
  temperature: number
  humidity: number
  untransmittedRawChunks: number
  untransmittedEnvChunks: number
  untransmittedKeys: number
  worn: boolean
  onCharger: boolean
  functionTest: number
  storageUsed: number | null
}

export const state = () => ({
  connections: [] as Array<DeviceConnectionInfo>,
})

export type DeviceConnectionsState = ReturnType<typeof state>

export const mutations: MutationTree<DeviceConnectionsState> = {
  FETCH_DEVICE_CONNECTIONS(state, data: DeviceConnectionEntity[]) {
    state.connections = data.map((e) => ({
      ...e,
      id: toInstant(e.time).toEpochMilli(),
      time: toInstant(e.time),
      deviceTime: toInstant(e.deviceTime),
      storageUsed: e.storageUsed
        ? Math.round(e.storageUsed * 1000) / 1000
        : null,
    }))
  },
  RESET_DEVICE_CONNECTIONS(state) {
    state.connections = [] as Array<DeviceConnectionInfo>
  },
}

export const actions: ActionTree<DeviceConnectionsState, RootState> = {
  async fetchDeviceConnections({ commit }, patientId: string) {
    const entries = await this.$axios.$get<DeviceConnectionEntity[]>(
      `/v1/patients/${patientId}/device-connections`
    )

    commit('FETCH_DEVICE_CONNECTIONS', entries)
  },
  resetDeviceConnections({ commit }) {
    commit('RESET_DEVICE_CONNECTIONS')
  },
}
