import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { toInstant } from './utils'

export interface WearingAdherenceResponse {
  id: number
  time: Instant
  response: string
}

export interface WearingAdherenceResponseEntity {
  time: string
  question: { id: string }
  response: string
}

export const state = () => ({
  adherences: [] as Array<WearingAdherenceResponse>,
})

export type WearingAdherenceState = ReturnType<typeof state>

export const mutations: MutationTree<WearingAdherenceState> = {
  FETCH_WEARING_RESPONSES(state, entities: WearingAdherenceResponseEntity[]) {
    state.adherences = entities
      .filter((_) => true)
      .map((entity) => {
        return {
          ...entity,
          id: toInstant(entity.time).toEpochMilli(),
          time: toInstant(entity.time),
        }
      })
  },
}

export const actions: ActionTree<WearingAdherenceState, RootState> = {
  async fetchWearingAdherenceResponses({ commit }, pid) {
    const entities = await this.$axios.$get<WearingAdherenceResponseEntity[]>(
      `/v1/interviews/${pid}/responses`
    )
    commit('FETCH_WEARING_RESPONSES', entities)
  },
}
