// https://docs.ambeedata.com/#pollen-intro
import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState, UserQuery } from '~/store'
import { toInstant } from './utils'

export interface PollenEntity {
  since: string
  grass: number
  tree: number
  weed: number
  size: number
}

export interface PollenInfo extends Omit<PollenEntity, 'since'> {
  since: Instant
}

export const state = () => ({
  infos: [] as Array<PollenInfo>,
})

export type PollenState = ReturnType<typeof state>

export const mutations: MutationTree<PollenState> = {
  FETCH_POLLEN(state, data: PollenEntity[]) {
    state.infos = data.map((e) => ({
      ...e,
      since: toInstant(e.since),
    }))
  },
}

export const actions: ActionTree<PollenState, RootState> = {
  async fetchPollen({ commit }, query: UserQuery) {
    const pollenData = await this.$axios.$get<PollenEntity[]>(`/v1/pollen`, {
      params: {
        patientId: query.uid,
        since: query.since?.toString(),
        until: query.until?.toString(),
        interval: query.interval < 60 ? 60 : query.interval, // MME: Weather Intervall should be minimal 60 minutes. A finer resolution is not available
      },
    })
    commit('FETCH_POLLEN', pollenData)
  },
}
