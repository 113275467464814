import { Instant, LocalDate, ZonedDateTime, ZoneId } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { Site, SiteEntity, toSiteModel } from './site'
import { toInstant } from './utils'

export enum PatientStudyStatus {
  Screening = 'Screening',
  Randomized = 'Randomized',
  Offboarding = 'Offboarding',
  Offboarded = 'Offboarded',
  Rescreening = 'Rescreening',
  Randomized_Pausing = 'Randomized_Pausing',
  Randomized_Paused = 'Randomized_Paused',
  Screening_Pausing = 'Screening_Pausing',
  Screening_Paused = 'Screening_Paused',
  Rescreening_Pausing = 'Rescreening_Pausing',
  Rescreening_Paused = 'Rescreening_Paused',
  Failed_Offboarding = 'Failed_Offboarding',
  Failed_Offboarded = 'Failed_Offboarded',
  Failed_Offboarding_Final = 'Failed_Offboarding_Final', // State after Rescreening Failed
  Failed_Offboarded_Final = 'Failed_Offboarded_Final',
}

export enum ComplianceEligible {
  Yes,
  Open,
  No,
}

export enum AdherenceResponse {
  NoResponse = 'NoResponse',
  NotAtAll = 'NotAtAll',
  SomeOfDay = 'SomeOfDay',
  MostOfDay = 'MostOfDay',
  AllDay = 'AllDay',
}

export interface PatientHistory {
  date: LocalDate
  status: string
  appInteractions: number
  wearableInteractions: number
  uploadAudioSize: number
  wornMinutes?: number
  adherenceResponse: AdherenceResponse
}

export interface PatientHistoryEntity {
  date: string
  status: string
  appInteractions: number
  wearableInteractions: number
  uploadAudioSize: number
  wornMinutes: number
  adherenceResponse: number
}

export interface PatientStudyStatusLog {
  date: LocalDate
  status: PatientStudyStatus
}

export interface PatientStudyStatusLogEntity {
  time: string
  status: PatientStudyStatus
}

export interface Device {
  id: string
  macAddress: string
  serialNumber: string
  lastUpload: Instant | null
}

export interface LastAppInteraction {
  lastInteraction: Instant | null
  lastDeviceConnection: Instant | null
}

export interface PatientOverview {
  id: string
  customId: string
  email?: string
  firstname: string
  lastname: string
  onboarding: ZonedDateTime
  site: Site
  lastAppInteraction: LastAppInteraction | null
  lastStatus: PatientStudyStatusLog
  device: Device | null
  eligible: ComplianceEligible
  history: PatientHistory[]
  storageUsed: number | null
}

export interface DeviceEntity {
  id: string
  macAddress: string
  serialNumber: string
  lastUpload: string | null
}

export interface LastAppInteractionEntity {
  lastInteraction: string | null
  lastDeviceConnection: string | null
}

export interface PatientOverviewEntity {
  id: string
  email: string
  firstname: string
  lastname: string
  onboarding: string
  site: SiteEntity
  lastStatus: PatientStudyStatusLogEntity
  lastAppInteraction: LastAppInteractionEntity | null
  device: DeviceEntity | null
  customId: string
  eligible: ComplianceEligible
  history: PatientHistoryEntity[]
  storageUsed: number | null
}

export const state = () => ({
  patient: {} as PatientOverview,
  statusLog: [] as Array<PatientStudyStatusLog>,
})

export type PatientStudyState = ReturnType<typeof state>

function toAdherenceResponse(val: number): AdherenceResponse {
  switch (val) {
    case 0:
      return AdherenceResponse.NotAtAll
    case 1:
      return AdherenceResponse.SomeOfDay
    case 2:
      return AdherenceResponse.MostOfDay
    case 3:
      return AdherenceResponse.AllDay
    case -1:
    default:
      return AdherenceResponse.NoResponse
  }
}

function toPatientHistoryModel(entity: PatientHistoryEntity): PatientHistory {
  return {
    ...entity,
    adherenceResponse: toAdherenceResponse(entity.adherenceResponse),
    date: LocalDate.parse(entity.date),
  }
}

export function toPatientOverviewModel(
  entity: PatientOverviewEntity
): PatientOverview {
  const site = toSiteModel(entity.site)
  const onboarding = toInstant(entity.onboarding).atZone(site.timezone)
  return {
    ...entity,
    onboarding,
    site,
    history: entity.history
      .map(toPatientHistoryModel)
      .filter((model) => model.date.compareTo(onboarding.toLocalDate()) >= 0),
    lastStatus: {
      status: entity.lastStatus.status,
      date: toInstant(entity.lastStatus.time)
        .atZone(site.timezone)
        .toLocalDate(),
    },
    lastAppInteraction: entity.lastAppInteraction
      ? {
          lastInteraction: entity.lastAppInteraction.lastInteraction
            ? toInstant(entity.lastAppInteraction.lastInteraction)
            : null,
          lastDeviceConnection: entity.lastAppInteraction.lastDeviceConnection
            ? toInstant(entity.lastAppInteraction.lastDeviceConnection)
            : null,
        }
      : null,
    device: entity.device
      ? {
          ...entity.device,
          lastUpload: entity.device?.lastUpload
            ? toInstant(entity.device.lastUpload)
            : null,
        }
      : null,
  }
}

export const mutations: MutationTree<PatientStudyState> = {
  FETCH_PATIENT_OVERVIEW(state, entity: PatientOverviewEntity) {
    state.patient = toPatientOverviewModel(entity)
  },
  FETCH_STUDY_STATUS_LOG(state, entities: PatientStudyStatusLogEntity[]) {
    state.statusLog = entities.map(
      (entity): PatientStudyStatusLog => ({
        ...entity,
        date: toInstant(entity.time).atZone(ZoneId.UTC).toLocalDate(),
      })
    )
  },
  RESET_PATIENT(state) {
    state.patient = {} as PatientOverview
  },
}

export const actions: ActionTree<PatientStudyState, RootState> = {
  async fetchPatientOverview({ commit }, pid) {
    const entity = await this.$axios.$get<PatientOverviewEntity>(
      `/v1/patients/${pid}/overview`
    )
    commit('FETCH_PATIENT_OVERVIEW', entity)
  },
  async fetchStatusLog({ commit }, pid) {
    const entities = await this.$axios.$get<PatientStudyStatusLogEntity[]>(
      `/v1/patients/${pid}/study-status-logs`
    )
    commit('FETCH_STUDY_STATUS_LOG', entities)
  },
  resetPatient({ commit }) {
    commit('RESET_PATIENT')
  },
}

export function getAdherenceRatio(patient: PatientOverview): number | null {
  const skipToday = (v: PatientHistory) => {
    const today = LocalDate.now(patient.site.timezone)
    return v.date.compareTo(today) !== 0
  }
  const skipNoData = (v: PatientHistory) => hasWornData(v.wornMinutes)

  const filteredHistory = patient.history.filter(skipToday).filter(skipNoData)
  if (filteredHistory.length === 0) {
    return null
  }

  const amountOfWornDays = filteredHistory.reduce(
    (acc, h) => (h.wornMinutes! > 8 * 60 ? acc + 1 : acc),
    0
  )
  return amountOfWornDays / filteredHistory.length
}

export function getAdherenceResponseRatio(
  patient: PatientOverview
): number | null {
  const skipToday = (v: PatientHistory) => {
    const today = LocalDate.now(patient.site.timezone)
    return v.date.compareTo(today) !== 0
  }

  const filteredHistory = patient.history.filter(skipToday)
  if (filteredHistory.length === 0) {
    return null
  }

  const weightedAnswers = filteredHistory.reduce((acc, history) => {
    switch (history.adherenceResponse) {
      case AdherenceResponse.AllDay:
      case AdherenceResponse.MostOfDay:
        return acc + 1
      case AdherenceResponse.SomeOfDay:
        return acc + 0.5
      case AdherenceResponse.NotAtAll:
      case AdherenceResponse.NoResponse:
      default:
        return acc
    }
  }, 0)

  return weightedAnswers / filteredHistory.length
}

export function hasWornData(wornMinutes?: number): boolean {
  return (
    wornMinutes !== undefined &&
    typeof wornMinutes !== 'undefined' &&
    wornMinutes !== null
  )
}
