import { Instant } from '@js-joda/core'
import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { toInstant } from './utils'

export interface ChunkListEntity {
  id: string
}

export interface ChunksInfo {
  id: string
  processingState: string
  encKeyId: number
  type: string
  uploadTime: Instant
}

export interface ChunksEntity {
  id: string
  processingState: string
  encKeyId: number
  aesIVs: number
  type: string
  uploadTime: string
}

export const state = () => ({
  chunks: [] as Array<ChunksInfo>,
})

export type ChunksState = ReturnType<typeof state>

export const mutations: MutationTree<ChunksState> = {
  FETCH_CHUNKS(state, data: ChunksEntity[]) {
    state.chunks = data.map((e) => ({
      ...e,
      uploadTime: toInstant(e.uploadTime),
    }))
  },
}

export const actions: ActionTree<ChunksState, RootState> = {
  async fetchChunks({ commit }, deviceId: string) {
    const chunkList = await this.$axios.$get<ChunkListEntity[]>(
      `/v1/devices/${deviceId}/chunks`
    )
    const chunks = await Promise.all(
      chunkList.map(
        async (id) =>
          await this.$axios.$get<ChunksEntity>(
            `/v1/devices/${deviceId}/chunks/${id}/meta`
          )
      )
    )
    commit('FETCH_CHUNKS', chunks)
  },
}
