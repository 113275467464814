export default function ({ $axios, store }) {
  const responseTimeRegex =
    /^s=([-+]?[0-9]*\.?[0-9]+);e=([-+]?[0-9]*\.?[0-9]+)$/g

  $axios.onRequest((config) => {
    config.headers['X-Date'] = performance.now() + performance.timeOrigin
  })

  $axios.onResponse((response) => {
    try {
      const match = responseTimeRegex.exec(response.headers['x-date'])
      if (!match || match.length < 2) {
        return
      }

      store.dispatch('time/update', {
        clientTimeStart: Number.parseFloat(response.config.headers['X-Date']),
        clientTimeEnd: performance.now() + performance.timeOrigin,
        serverTimeStart: Number.parseFloat(match[1]),
        serverTimeEnd: Number.parseFloat(match[2]),
      })
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex)
    }
  })
}
