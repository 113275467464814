
import { Instant } from '@js-joda/core'
import Vue from 'vue'
import { AuthActions, AuthGetters, authStore } from '~/store/auth'

/** Cookie name to remember the last activity on the website */
const INACTIVITY_COOKIE_NAME = 'lastActivity'

/** Milliseconds until autologout should occure. */
const INACTIVITY_LENGTH_MS = 86400_000 // 1 day

export default Vue.extend({
  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      timer: null as NodeJS.Timeout | null,
    }
  },
  mounted() {
    // Set timers for inactivity to log out
    for (const event of this.events) {
      window.addEventListener(event, this.resetTimer)
    }
    this.setTimers()
  },
  destroyed() {
    for (const event of this.events) {
      window.removeEventListener(event, this.resetTimer)
    }
    this.resetTimer()
  },
  methods: {
    setTimers() {
      const lastActivityRaw = this.$cookies.get<number | string>(
        INACTIVITY_COOKIE_NAME
      )
      const lastActivity = lastActivityRaw
        ? Instant.ofEpochMilli(Number(lastActivityRaw))
        : Instant.EPOCH
      const loggedInExpireAt = lastActivity.plusMillis(INACTIVITY_LENGTH_MS)

      // Instant logout, if the login is expired and the user is still logged in.
      const isLoggedIn = this.$store.getters[authStore(AuthGetters.hasUser)]
      if (Instant.now().isAfter(loggedInExpireAt) && isLoggedIn) {
        this.logoutUser()
      }

      // Set timer for auto logouts
      this.timer = setTimeout(() => {
        this.logoutUser()
      }, INACTIVITY_LENGTH_MS)

      // Remembers timestamp of last activity
      this.$cookies.set(INACTIVITY_COOKIE_NAME, Instant.now().toEpochMilli(), {
        maxAge: INACTIVITY_LENGTH_MS,
        path: '/',
      })
    },
    resetTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.setTimers()
    },
    async logoutUser() {
      await this.$store.dispatch(authStore(AuthActions.logout))
      await this.$router.push(this.localePath('/'))

      // Remembers timestamp because a logout will be a last activity as well
      this.$cookies.set(INACTIVITY_COOKIE_NAME, Instant.now().toEpochMilli(), {
        maxAge: INACTIVITY_LENGTH_MS,
        path: '/',
      })
    },
  },
})
