import { datadogRum } from '@datadog/browser-rum'

export default ({
  $config: {
    datadogApplicationId,
    datadogApplicationToken,
    datadogSite,
    env,
    version,
  },
}) => {
  datadogRum.init({
    applicationId: datadogApplicationId,
    clientToken: datadogApplicationToken,
    site: datadogSite,
    service: 'siva_hcp_dashboard',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
  datadogRum.startSessionReplayRecording()
}
