import { Instant } from '@js-joda/core'
import type { GetterTree, MutationTree, Store } from 'vuex/types'
import type { UserState as AuthState } from './auth'
import type { DeviceState } from './device'
import type { MannitolChallengeState } from './mannitolChallenge'
import type { PatientState } from './patient'

export const state = () => ({
  focusMode: false,
})

export type RootState = ReturnType<typeof state> & {
  auth: AuthState
  patient: PatientState
  device: DeviceState
  mannitolChallenge: MannitolChallengeState
}

export interface CommonQuery {
  since?: Instant
  until?: Instant
  interval: number
}

export interface UserQuery extends CommonQuery {
  uid: string
}

export interface GeoQuery extends CommonQuery {
  latitude: number
  longitude: number
  range: number
}

export const getState = (store: Store<any>) => store.state as RootState

export const getters: GetterTree<RootState, RootState> = {
  isFocusMode: (state) => state.focusMode,
}

export const mutations: MutationTree<RootState> = {
  SET_FOCUS_MODE(state: RootState, focusMode: boolean) {
    state.focusMode = focusMode
  },
}
