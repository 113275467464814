import {
  DateTimeFormatter,
  Duration,
  Instant,
  LocalDateTime,
  ZoneOffset,
} from '@js-joda/core'

export const DATETIME_FORMATTER = DateTimeFormatter.ofPattern(
  "yyyy-MM-dd'T'HH:mm[:ss[.SSS]][X]"
)

export function toInstant(text?: string | null): Instant {
  if (!text) {
    return Instant.EPOCH
  }

  return LocalDateTime.parse(text, DATETIME_FORMATTER).toInstant(ZoneOffset.UTC)
}

export function getDaysSince(timestamp?: string | null): number {
  if (timestamp) {
    return Duration.between(toInstant(timestamp), Instant.now()).toDays()
  } else {
    return -1
  }
}

export const formatMacAddress = (macAddress?: string): string => {
  return macAddress?.replace(/(..)(?!$)/g, '$1:') ?? '-'
}
