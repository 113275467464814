import { Middleware } from '@nuxt/types'
import { AuthGetters, authStore } from '~/store/auth'

const adminMiddleware: Middleware = ({ store, error }) => {
  // If the user is not authenticated
  if (store.getters[authStore(AuthGetters.isAdmin)]) {
    // valid admin user
  } else {
    error({ statusCode: 403, message: 'Access denied' })
  }
}

export default adminMiddleware
