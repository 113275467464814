import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { Site, SiteEntity, toSiteModel } from './site'

export interface SiteUser {
  id: string
  firstname: string
  lastname: string
  email: string
  site: Site
}

export interface SiteUserEntity {
  id: string
  firstname: string
  lastname: string
  email: string
  site: SiteEntity
}

export enum SiteUserActions {
  fetchSiteUsers = 'fetchSiteUsers',
  fetchSiteUser = 'fetchSiteUser',
}

export const siteUserStore = (method: SiteUserActions) => `siteUser/${method}`

export const state = () => ({
  users: [] as Array<SiteUser>,
  user: {} as SiteUser,
})

export type SiteUserState = ReturnType<typeof state>

export const mutations: MutationTree<SiteUserState> = {
  FETCH_SITE_USERS(state, entities: SiteUserEntity[]) {
    state.users = entities
      .map(toSiteUserModel)
      .sort((a, b) =>
        (a.lastname + a.firstname).localeCompare(b.lastname + b.firstname)
      )
  },

  FETCH_SITE_USER(state, entity: SiteUserEntity) {
    state.user = toSiteUserModel(entity)
  },
}

export const actions: ActionTree<SiteUserState, RootState> = {
  async [SiteUserActions.fetchSiteUsers]({ commit }) {
    const userEntities = await this.$axios.$get<SiteUserEntity[]>(
      `/v1/site-users`
    )
    commit('FETCH_SITE_USERS', userEntities)
  },
  async [SiteUserActions.fetchSiteUser]({ commit }, userId) {
    const userEntity = await this.$axios.$get<SiteUserEntity>(
      `/v1/site-users/${userId}`
    )
    commit('FETCH_SITE_USER', userEntity)
  },
}

export const toSiteUserModel = (entity: SiteUserEntity): SiteUser => {
  return {
    id: entity.id,
    firstname: entity.firstname,
    lastname: entity.lastname,
    email: entity.email,
    site: toSiteModel(entity.site),
  }
}
