import { Instant } from '@js-joda/core'
import { toInstant } from './utils'

export interface AppInteraction {
  id: number
  time: Instant
}

export interface AppInteractionEntity {
  time: string
}

export function parseAppInteractionEntity(
  entity: AppInteractionEntity
): AppInteraction {
  const time = toInstant(entity.time)
  return {
    id: time.toEpochMilli(),
    time,
  }
}
