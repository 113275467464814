import { ActionTree, MutationTree } from 'vuex/types'
import { RootState } from '~/store'
import { SiteGroup, SiteGroupEntity } from './siteGroup'

export interface SiteGroupUser {
  id: string
  firstname: string
  lastname: string
  email: string
  siteGroup: SiteGroup
}

export interface SiteGroupUserEntity {
  id: string
  firstname: string
  lastname: string
  email: string
  siteGroup: SiteGroupEntity
}

export enum SiteGroupUserActions {
  fetchSiteGroupUsers = 'fetchSiteGroupUsers',
  fetchSiteGroupUser = 'fetchSiteGroupUser',
}

export const siteGroupUserStore = (method: SiteGroupUserActions) =>
  `siteGroupUser/${method}`

export const state = () => ({
  siteGroupUsers: [] as Array<SiteGroupUser>,
  siteGroupUser: {} as SiteGroupUser,
})

export type SiteGroupUserState = ReturnType<typeof state>

export const mutations: MutationTree<SiteGroupUserState> = {
  FETCH_SITE_GROUP_USERS(state, entities: SiteGroupUserEntity[]) {
    state.siteGroupUsers = entities
      .map(toSiteGroupUserModel)
      .sort((a, b) =>
        (a.lastname + a.firstname).localeCompare(b.lastname + b.firstname)
      )
  },
  FETCH_SITE_GROUP_USER(state, entity: SiteGroupUserEntity) {
    state.siteGroupUser = toSiteGroupUserModel(entity)
  },
}

export const actions: ActionTree<SiteGroupUserState, RootState> = {
  async [SiteGroupUserActions.fetchSiteGroupUsers]({ commit }) {
    const userEntities = await this.$axios.$get<SiteGroupUserEntity[]>(
      `/v1/site-group-users`
    )
    commit('FETCH_SITE_GROUP_USERS', userEntities)
  },
  async [SiteGroupUserActions.fetchSiteGroupUser]({ commit }, userId) {
    const userEntity = await this.$axios.$get<SiteGroupUserEntity>(
      `/v1/site-group-user/${userId}`
    )
    commit('FETCH_SITE_GROUP_USER', userEntity)
  },
}

export const toSiteGroupUserModel = (
  entity: SiteGroupUserEntity
): SiteGroupUser => {
  return {
    ...entity,
  }
}
